import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import html2pdf from "html2pdf.js";
import _get from "lodash/get";
import { TextField } from "@mui/material";

function RenderTableRows({ item, index }) {
  const vehicle = item || {}; 
  return (
    <TableRow hover style={{ cursor: "pointer" }} key={index}>
      <TableCell style={{ textTransform: "capitalize" }}>
        {_get(vehicle, "name", "N/A")}
      </TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {_get(vehicle, "vin", "N/A")}
      </TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {_get(vehicle, "license.plate", "N/A")}
      </TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {_get(vehicle, "license.state", "N/A")}
      </TableCell>
    </TableRow>
  );
}

export default function RenderTable({ vehicles = [] }) {
   const [searchQuery,SetSearchQuery]=useState('');
  const [downloading, setDownloading] = useState(false);


  const filterVehicles=vehicles.filter((driver)=>{
    const unitNumber=driver?.name?.toLowerCase() || ''
    const vin=driver?.vin?.toLowerCase() || ''
    const licenseState=driver?.license?.State?.toLowerCase() || ''
    const search= searchQuery.toLowerCase()
    return (
      unitNumber.includes(search) ||
      vin.includes(search) ||
      licenseState.includes(search)
    ) 
  })

   async function download() {
      try {
        setDownloading(true);
        const element = document.getElementById("All-Vehicle-report");
        if (!element) {
          console.error("Element with ID 'Vehicle-report' not found.");
          setDownloading(false);
          return;
        }
        const pdfOptions = {
          filename: "Vehicle-report",
          margin: [10, 10],
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        await html2pdf().set(pdfOptions).from(element).save();
      } catch (error) {
        console.error("Error while downloading PDF:", error);
      } finally {
        setDownloading(false);
      }
    }
  
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <TextField
          id="standard-basic"
          label="Search..."
          variant="outlined"
          size="small"
          inputProps={{
            style: { fontSize: "12px", }, 
          }}
          InputLabelProps={{
            style: { fontSize: "12px" }, 
          }}
          value={searchQuery}
          onChange={(e)=>SetSearchQuery(e.target.value)}
        />
        <Button onClick={download} 
        size="small"
         variant="contained"
         style={{
          fontSize: "12px", 
          minWidth: "auto", // Remove default minWidth
        }}
         >
          {downloading ? "Downloading..." : "Download"}
        </Button>
      </div>
      <Grid item xs={12} md={12} lg={12} id="All-Vehicle-report">
        <Typography
          variant="h5"
          component="h2"
          style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
        >
          Vehicle Details
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="vehicle table">
            <TableHead>
              <TableRow>
                <TableCell>Unit Number</TableCell>
                <TableCell>Vin</TableCell>
                <TableCell>License Plate</TableCell>
                <TableCell>State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterVehicles.map((vehicle, idx) => (
                <RenderTableRows key={`vehicle-${idx}`} item={vehicle} index={idx} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
