import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./equipmentActionTypes";

export const setEquipmentsCount = (value) => ({
  type: TYPES.EQUIPMENTS_COUNT,
  value,
});

export const setAssetType = (value) => ({
  type: TYPES.EQUIPMENTS_ASSET_TYPE,
  value,
});

export const setEquipments = (value) => ({
  type: TYPES.EQUIPMENTS,
  value,
});

export const setTrailers = (value) => ({
  type: TYPES.TRAILERS,
  value,
});

export const selectedEquipment = (value) => ({
  type: TYPES.SELCTED_EQUIPMENT,
  value,
});

export const appEquipmentBusy = (value) => ({
  type: TYPES.APP_EQUIPMENT_BUSY,
  value,
});

export const setPagination = (value) => ({
  type: TYPES.EQUIPMENTS_PAGINATION,
  value,
});

export function getDtcs(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/dtcs`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data");
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getEquipmentsCount(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    dispatch(appEquipmentBusy(true));

    return axios({
      url: `${BASE_URL}/manager/${accountId}/equipments/count`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data.count", 0);
        dispatch(setEquipmentsCount(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(appEquipmentBusy(false));
        return response;
      });
  };
}

export function getVinInfo(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${BASE_URL}/manager/${accountId}/equipments/vin`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setEquipmentsCount(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(appEquipmentBusy(false));
        return response;
      });
  };
}

export function getEquipments(params = {}, accId) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    let accountId = "";

    if (accId) {
      accountId = accId;
    } else {
      accountId = _get(getState(), "authData.profile.user.accountId");
    }

    dispatch(appEquipmentBusy(true));
    dispatch(setEquipments([]));

    return axios({
      url: `${BASE_URL}/manager/${accountId}/equipments`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setEquipments(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(appEquipmentBusy(false));
        return response;
      });
  };
}

export function getTrailers(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    // params.order = _get(getState(), "equipmentData.pagination.order");
    // params.page = _get(getState(), "equipmentData.pagination.page");
    // params.limit = _get(getState(), "equipmentData.pagination.limit");
    // params.assetType = _get(getState(), "equipmentData.assetType");

    dispatch(appEquipmentBusy(true));
    dispatch(setEquipments([]));

    return axios({
      url: `${BASE_URL}/manager/${accountId}/equipments`,
      method: "get",
      params: {
        page: 0,
        limit: 1000,
        assetType: "TRAILER",
      },
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setTrailers(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(appEquipmentBusy(false));
        return response;
      });
  };
}

export function createEquipment(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    params.accountId = accountId;

    dispatch(appEquipmentBusy(true));

    return axios({
      url: `${BASE_URL}/manager/${accountId}/equipments`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(appEquipmentBusy(false));
        return response;
      });
  };
}

export function updateEquipment(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    params.accountId = accountId;

    dispatch(appEquipmentBusy(true));

    return axios({
      url: `${BASE_URL}/manager/${accountId}/equipments/${params.id}`,
      method: "put",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(appEquipmentBusy(false));
        return response;
      });
  };
}
