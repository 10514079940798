/* eslint-disable react-hooks/rules-of-hooks */
import { CircularProgress, Grid } from '@mui/material'
import _get from "lodash/get";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReportTable from "./ReportTable";
import { getEquipments } from '../../../actions/equipmentActions';

function index() {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [vehicles, setvehicles] = useState([]);
  
    const timezone = useSelector((state) =>
      _get(state, "authData.profile.account.terminal.timezone")
    );
  
    useEffect(() => {
      getallDrivers();
    }, []);
  
    async function getallDrivers() {
      try {
        const payload = {};
        setLoading(true);
        const response = await dispatch(getEquipments(payload));
        setLoading(false);
        if (response && Array.isArray(response)) {
          setvehicles(response);
        }
      } catch (e) {
        console.log(e);
      }
    }

  return (
    <div 
    style={{
      padding: "40px 10px 40px 40px",
    }}
    >
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            {loading ? (
              <div style={{ textAlign: "center", marginTop: 55 }}>
                <CircularProgress />
              </div>
            ) : (
              <ReportTable timezone={timezone}  vehicles={vehicles}/>
            )}
          </Grid>
        </Grid>
    </div>
  )
}

export default index