import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import SearchBar from "../../../components/SearchBar";

function Report(props) {
  const [loading, setLoading] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [logs, setLogs] = useState([]);

  function handleSearchChange() {
    setDownload(true);
    setLogs([{ id: 1 }]);
  }

  function onDownload() {}

  return (
    <div
      style={{
        padding: "40px 10px 40px 40px",
      }}
    >
      <Grid container className={{}}>
        <Grid item xs={12}>
          <SearchBar
            options={{
              isEqp: false,
              isDriver: true,
              isStartDate: true,
              isEndDate: true,
              isTime: false,
              isTimeperiod: false,
              dl: true,
              isDownload: isDownload,
              isDownloadIcon: true,
            }}
            loading={loading}
            onSearch={handleSearchChange}
            onDownload={onDownload}
          />
        </Grid>
        {logs && Array.isArray(logs) && logs.length > 0 && (
          <Grid item xs={12}>
            <h2 style={{ textAlign: "center" }}>{`Coming soon...`}</h2>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Report;
