import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { withRouter } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

const STYLES = {
  mainDiv: {
    margin: "0 50px 0",
  },
  table: {
    border: "0.1px solid #ccc",
    width: "98%",
    borderCollapse: "collapse",
    margin: "auto",
    fontSize: 13,
    textAlign: "left",
  },
  tr: {
    border: "0.2px solid #ccc",
    padding: "20px 0",
  },
  td: {
    textTransform: "capitalize",
  },
  tStyle: {
    padding: "20px 0",
  },
  reportHead: {
    textAlign: "center",
    fontSize: "20px",
    marginBottom: "10px",
  },
};

function MileDetailModal(props) {
  const miles = props.rawMiles || [];

  return (
    <Dialog
      fullScreen
      className={""}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={props.onClose}>
        <Typography style={{ minWidth: "800px" }}>{"Details"}</Typography>
        <IconButton
          aria-label="Close"
          className={""}
          onClick={props.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <table style={STYLES.table}>
            <thead>
              <tr>
                <th>State</th>
                <th>Action</th>
                <th>Odometer</th>
                <th>Created</th>
                <th>By</th>
              </tr>
            </thead>
            <tbody>
              {miles.map((mile) => {
                const createdBy = mile.createdBy || {};
                return (
                  <tr style={STYLES.tr} key={mile._id}>
                    <td style={STYLES.td}>{mile.state}</td>
                    <td style={STYLES.td}>{mile.action}</td>
                    <td>{`${Math.round(mile.odometer)}`}</td>
                    <td>{mile.created}</td>
                    <td>{createdBy.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default MileDetailModal;
