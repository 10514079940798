import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import { getInfoArrayDownload } from "./infoHelper";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
  table: {
    width: "100%",
    // border: "1px solid #f0f0f0",
    padding: 0,
    fontSize: 14,
  },
  title: {
    display: "inline-block",
    minWidth: 85,
  },
  title2: {
    display: "inline-block",
    minWidth: 125,
  },
  title3: {
    display: "inline-block",
    minWidth: 125,
  },
  titleD: {
    display: "inline-block",
    minWidth: 60,
    paddingLeft: 0,
  },
  titleD2: {
    display: "inline-block",
    minWidth: 70,
    paddingLeft: 0,
  },
  titleD3: {
    display: "inline-block",
    minWidth: 70,
    paddingLeft: 0,
  },
});

export class DailyStatusDriverInfo extends React.Component {
  render() {
    const { classes, handleClick } = this.props;
    const allItems = getInfoArrayDownload(this.props);
    return (
      <Table size="small" className={`${classes.table}`}>
        <TableBody>
          {allItems.map((item) => {
            return (
              <TableRow key={item.label}>
                <TableCell style={{ paddingLeft: 0, paddingRight: 1 }}>
                  <span className={`${classes.titleD}`}>{item.label}:</span>
                  <span
                    className={`${classes.border} ${classes.tdTwo}`}
                    style={{
                      textTransform:
                        item.label === "EMAIL" || item.label === "TRAILERS"
                          ? "none"
                          : "capitalize",
                    }}
                  >
                    {item.value}
                  </span>
                </TableCell>

                <TableCell style={{ paddingLeft: 0, paddingRight: 1 }}>
                  <span className={`${classes.titleD2}`}>
                    {item.labelMiddle}:
                  </span>
                  <span className={`${classes.content2}`}>
                    {item.valueMiddle}
                  </span>
                </TableCell>
                <TableCell style={{ paddingLeft: 0, paddingRight: 1 }}>
                  <span className={`${classes.titleD3}`}>
                    {item.labelRight}:
                  </span>
                  <span className={`${classes.content}`}>
                    {item.valueRight}
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(DailyStatusDriverInfo);
