import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import html2pdf from "html2pdf.js";
import _get from "lodash/get";
import { TextField } from "@mui/material";

function RenderTableRows({ item, index }) {
  const driver = item || {};

  return (
    <TableRow hover style={{ cursor: "pointer" }} key={index}>
      <TableCell style={{ textTransform: "capitalize" }}>
        {_get(driver, "alias", "")}
      </TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {_get(driver, "firstName", "")}
      </TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {_get(driver, "lastName", "")}
      </TableCell>
      <TableCell>{_get(driver, "email", "N/A")}</TableCell>
      <TableCell>{_get(driver, "licenseNumber", "N/A")}</TableCell>
      <TableCell>{_get(driver, "licenseState", "N/A")}</TableCell>
    </TableRow>
  );
}

export default function RenderTable({ drivers = [] }) {
  const [searchQuery, SetSearchQuery] = useState('');
  const [downloading, setDownloading] = useState(false);

  const filterDriver = drivers.filter((driver) => {
    const firstName = driver?.firstName?.toLowerCase() || ''
    const lastName = driver.lastName?.toLowerCase() || ''
    const email = driver.email.toLowerCase() || ''
    const licenseNumber = driver.licenseNumber.toLowerCase() || ''
    const licenseState = driver.licenseState.toLowerCase() || ''
    const search = searchQuery.toLowerCase()
    return (
      firstName.includes(search) ||
      lastName.includes(search) ||
      email.includes(search) ||
      licenseNumber.includes(search) ||
      licenseState.includes(search)
    )
  })
  async function download() {
    try {
      setDownloading(true);
      const element = document.getElementById("Drivers-report");
      if (!element) {
        console.error("Element with ID 'Drivers-report' not found.");
        setDownloading(false);
        return;
      }
      const pdfOptions = {
        filename: "Drivers-report.pdf",
        margin: [10, 10],
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      await html2pdf().set(pdfOptions).from(element).save();
    } catch (error) {
      console.error("Error while downloading PDF:", error);
    } finally {
      setDownloading(false);
    }
  }

  return (
    <>
      <div style={{
        textAlign: "right",
        display: 'flex',
        justifyContent: "flex-end",
        gap: "10px"
      }}>
        <TextField
          id="standard-basic"
          label="Search..."
          variant="outlined"
          value={searchQuery}
          onChange={(e) => SetSearchQuery(e.target.value)}
          size="small"
          inputProps={{
            style: { fontSize: "12px", },
          }}
          InputLabelProps={{
            style: { fontSize: "12px" },
          }}
        />
        <Button onClick={download}
          size="small"
          variant="contained"
          style={{
            fontSize: "12px",
            minWidth: "auto", // Remove default minWidth
          }}
        >
          {downloading ? "Downloading..." : "Download"}
        </Button>
      </div>
      <Grid item xs={12} md={12} lg={12} id="Drivers-report">
        <Typography
          variant="h5"
          component="h2"
          style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
        >
          Drivers Details
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="drivers table">
            <TableHead>
              <TableRow>
                <TableCell>Driver ID</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>License Number</TableCell>
                <TableCell>License State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterDriver.length > 0 ? (
                filterDriver.map((driver, idx) => (
                  <RenderTableRows key={`driver-${idx}`} item={driver} index={idx} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    No data found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
