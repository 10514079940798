import _get from "lodash/get";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import ReportTable from "./ReportTable";
import { getDriversRealTimeBeta } from "../../../actions/driverActions";
import CircularProgress from "@mui/material/CircularProgress";

function Report(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const timezone = useSelector((state) =>
    _get(state, "authData.profile.account.terminal.timezone")
  );

  useEffect(() => {
    getRealtime();
  }, []);

  async function getRealtime() {
    try {
      const payload = {};
      setLoading(true);
      const response = await dispatch(getDriversRealTimeBeta(payload));
      setLoading(false);
      if (response && Array.isArray(response)) {
        setLogs(response);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div
      style={{
        padding: "40px 10px 40px 40px",
      }}
    >
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          {loading ? (
            <div style={{ textAlign: "center", marginTop: 55 }}>
              <CircularProgress />
            </div>
          ) : (
            <ReportTable timezone={timezone} items={logs} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Report;
