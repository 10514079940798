/* eslint-disable no-undef */
import React from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
import { Alert } from "@mui/lab";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pdfOptions } from "../../../helpers/helpers";
import { getIftaMiles } from "../../../actions/stateMileageActions";
import CircularProgress from "@mui/material/CircularProgress";
import SearchBar from "../../../components/SearchBar";
import MileTable from "./MileTable";
import MileDetailModal from "./MileDetailModal";
import { LABELS as _LABELS } from "../../../language";
// import { getMeterToMi, parseStateMileage } from "./helpers";

class StateMileage extends React.Component {
  state = {
    limit: 10,
    page: 0,
    loading: false,
    rawMiles: [],
    miles: [],
    errorMessage: null,
    isDetails: false,
  };

  async componentDidMount() {}

  getMiles = async () => {
    const { vehicleId, from, to } = this.state;
    if (!vehicleId) {
      this.setState({
        errorMessage: _LABELS.selectVehicle || "Please select a vehicle",
        loading: false,
        miles: [],
      });
      return;
    }

    this.setState({ miles: [], loading: true, errorMessage: null });

    try {
      const response = await this.props.getIftaMiles({
        vehicleId: vehicleId,
        from: from,
        to: to,
      });
      this.setState({ rawMiles: response });

      let objMile = {};
      for (let i = 0; i < response.length; i++) {
        const mile = response[i] || {};
        const curState = mile.state || "";
        objMile[curState] = objMile[curState] || {};
        objMile[curState].total = objMile[curState].total || 0;
        objMile[curState].logs = objMile[curState].logs || [];
        objMile[curState].logs.push(mile);
      }

      const arrayMiles = Object.keys(objMile);

      for (var idx = 0; idx < arrayMiles.length; idx++) {
        const selState = arrayMiles[idx];
        const logs = objMile[selState].logs;
        const total = objMile[selState].total;

        let enterOdo = 0;
        let exitOdo = 0;
        for (let jdx = 0; jdx < logs.length; jdx++) {
          const mile = logs[jdx] || {};
          if (mile.action === "enter") {
            enterOdo = mile.odometer;
          }
          if (mile.action === "exit" && enterOdo) {
            const diff = parseInt(mile.odometer) - parseInt(enterOdo);
            objMile[selState].total = parseInt(total) + parseInt(diff);
            enterOdo = 0;
          }
        }
      }

      const miles = Object.keys(objMile).map((state) => {
        const stateObj = objMile[state] || {};
        return {
          state: state,
          total: stateObj.total,
          logs: stateObj.logs,
        };
      });

      this.setState({ miles, loading: false });
    } catch (error) {
      this.setState({
        loading: false,
        errorMessage: "Error fetching miles data",
      });
    }
  };

  onSearch = ({ vehicleId, vehicle, start, end }) => {
    this.setState({ vehicleId, vehicle, from: start, to: end }, this.getMiles);
  };

  downloadReport = async () => {
    this.setState({ download: true });
    const fileName = `stateMileage.${"pdf"}`.replace(/ /g, "");
    window.scrollTo(0, 0);
    const element = document.getElementById("print_log");

    pdfOptions.filename = fileName;
    pdfOptions.margin = [0.3, 0.3, 0.3, 0.3];

    await html2pdf().set(pdfOptions).from(element).toPdf().save();
    this.setState({ download: false, saving: true });
  };

  render() {
    const { loading, errorMessage } = this.state;
    const isDetails = this.state.isDetails || false;
    const miles = this.state.miles || [];

    const isMiles = miles.length > 0;

    return (
      <>
        {isDetails && (
          <MileDetailModal
            open={isDetails}
            rawMiles={this.state.rawMiles}
            onClose={() => {
              this.setState({ isDetails: false });
            }}
          />
        )}
        <SearchBar
          options={{
            isEqp: true,
            isState: false,
            isStartDate: true,
            isEndDate: true,
            isTime: false,
            isTimeperiod: true,
            isPrefix: false,
            isDownload: true,
            isDownloadIcon: true,
          }}
          loading={loading}
          onSearch={this.onSearch}
          stateMil={true}
          onDownload={this.downloadReport}
        />

        {errorMessage && (
          <div style={{ margin: "20px" }}>
            <Alert severity="warning">{errorMessage}</Alert>
          </div>
        )}
        {this.state.diffOdometer && (
          <div style={{ marginLeft: 25 }}>
            Odometer: {this.state.diffOdometer}
          </div>
        )}
        <div
          style={{
            // maxWidth: 600,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 25,
          }}
        >
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {isMiles && (
                <div id="print_log">
                  <MileTable
                    miles={this.state.miles}
                    vehicle={this.state.vehicle || {}}
                    start={this.state.from}
                    end={this.state.to}
                    carrier={this.props.carrier}
                    vehicleId={this.state.vehicleId}
                    onDetails={() => {
                      this.setState({ isDetails: true });
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    equipments: _get(state, "equipmentData.equipments", []),
    adminKey: _get(state, "authData.profile.adminId"),
    carrier: _get(state, "authData.profile.account.carrier.name", ""),
    iftaApiKey: _get(state, "appData.IFTA_KEY", ""),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getIftaMiles,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(StateMileage);
