import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import _get from "lodash/get";
import { LABELS as _LABELS } from "../../../language";
import NotFoundTableRow from "../../NotFoundTableRow";
import moment from "moment";

const LABELS = _LABELS.settingPage;

const STYLES = {
  mainDiv: {
    margin: "0 50px 0",
  },
  table: {
    border: "0.1px solid #ccc",
    width: "98%",
    borderCollapse: "collapse",
    margin: "auto",
    fontSize: "13px",
  },
  td: {
    border: "0.2px solid #ccc",
    textAlign: "center",
    padding: "20px 0",
  },
  tStyle: {
    padding: "20px 0",
  },
  reportHead: {
    textAlign: "center",
    fontSize: "20px",
    marginBottom: "10px",
  },
};

function ReduxTableRow(props) {
  const item = props.item || {};
  if (item.total === 0) {
    return null;
  }
  return (
    <tr style={STYLES.td}>
      <td style={STYLES.tStyle}>{item.state}</td>
      <td>{`${item.total} Mile(s)`}</td>
    </tr>
  );
}

export default function MileTable(props) {
  const { miles = [], start, end, carrier, vehicle } = props;

  const formattedStart = start ? moment(start).format("MMM Do YYYY") : "N/A";
  const formattedEnd = end ? moment(end).format("MMM Do, YYYY") : "N/A";
  return (
    <>
      <Grid id="print_log" style={STYLES.mainDiv}>
        <Paper elevation={3} style={{ padding: "10px", minHeight: "550px" }}>
          <Grid item xs={12}>
            <p style={STYLES.reportHead}>{LABELS.state_mileage_report}</p>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <p style={{ marginLeft: "30px", marginBottom: "0" }}>
                  {`${LABELS.carrierName}: ${carrier || "N/A"}`}
                </p>
                <p style={{ marginLeft: "30px" }}>
                  {`${LABELS.report}: ${formattedStart} TO  ${formattedEnd}`}
                </p>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginBottom: "20px" }}>
                <p
                  style={{
                    marginRight: "30px",
                    marginBottom: "0",
                    textAlign: "right",
                  }}
                >
                  {`${LABELS.vehicleName} : ${
                    vehicle.name ? vehicle.name : "All Vehicles"
                  }`}
                </p>
                <p
                  style={{ marginRight: "30px", textAlign: "right" }}
                  onClick={props.onDetails}
                >
                  {`${LABELS.miles}: ${miles.reduce(
                    (sum, item) => sum + (item.total || 0),
                    0
                  )} Mile(s)`}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <table style={STYLES.table}>
            <thead>
              <tr>
                <th style={STYLES.tStyle}>{LABELS.state}</th>
                <th>{LABELS.mileage_miles}</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(miles) && miles.length > 0 ? (
                miles.map((item, index) => (
                  <ReduxTableRow
                    key={index}
                    item={item}
                    onDetails={props.onDetails}
                  />
                ))
              ) : (
                <NotFoundTableRow items={miles} />
              )}
            </tbody>
          </table>
        </Paper>
      </Grid>
    </>
  );
}
