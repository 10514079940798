import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import LogIn from "../containers/LogIn";

import Account from "../containers/Account";
import Payment from "../containers/Payment";
import AdminAudit from "../containers/AdminAudit";
import AdminUser from "../containers/AdminUser";
import AdminDetail from "../containers/Account/Detail";
import AdminSettings from "../containers/AdminSettings";
import AdminDashboard from "../containers/AdminDashboard";
import AdminReseller from "../containers/AdminReseller";
import AdminSupport from "../containers/AdminSupport";
import QueueDetail from "../containers/AdminSupport/QueueDetail";
import AdminShipping from "../containers/AdminShipping";
import AdminTodo from "../containers/AdminTodo";
import AdminLeads from "../containers/AdminLeads";
import AdminWhatsapp from "../containers/AdminWhatsapp";
import AdminResellerDetail from "../containers/AdminReseller/ResellerDetail";
import DashBoard from "../containers/AdminDashboardRecap/Dashboard";

import Dvir from "../containers/Dvir";
import DvirDetail from "../containers/Dvir/DvirDetail";
import Document from "../containers/Document";
import Fmcsa from "../containers/Fmcsa";
import FmcsaHistory from "../containers/Fmcsa/FmcsaHistory";
import FmcsaAdmin from "../containers/Fmcsa/FmcsaAdmin";
import Report from "../containers/Report";
import Setting from "../containers/Setting";
import SettingApp from "../containers/Setting/SettingApp";
import SettingProfile from "../containers/Setting/Profile";
import SettingAccount from "../containers/Setting/SettingAccount";
import SettingEditReason from "../containers/Setting/SettingEditReason";
import SettingExceptions from "../containers/Setting/SettingExceptions";
import SettingUser from "../containers/Setting/User";
import SettingApi from "../containers/Setting/Api";
import SettingMaintenance from "../containers/Setting/Maintenance";
import NotificationSetup from "../containers/Setting/NotificationSetup";
import DriverDetails from "../containers/Setting/User/DriverDetails";
import SettingCarrier from "../containers/Setting/Carrier";
import SettingTerminal from "../containers/Setting/Terminal";
import SettingEquipment from "../containers/Setting/Equipment";

import Violations from "../containers/Violations";
import Tracking from "../containers/Tracking";
import DailyDiary from "../containers/DailyDiary";
import DailyStatusDetail from "../containers/DailyStatus";
import DailyStatusMulti from "../containers/DailyStatus/DailyStatusMulti";
import VerifyLogs from "../containers/DailyStatus/VerifyLogs";
import ReviewLogs from "../containers/DailyStatus/ReviewLogs";
import Malfunctions from "../containers/Malfunctions";
import UnidentifiedDriving from "../containers/UnidentifiedDriving";
import Notifications from "../containers/Notifications";
import LogEdits from "../containers/LogEdits";
import LiveShare from "../containers/LiveShare";
import IftaMileage from "../containers/IftaMileage";
import IftaMileageBeta from "../containers/IftaMileage/IftaMileageBeta";
import IftaMileageDetail from "../containers/IftaMileage/IftaMileageDetail";
import Webview from "../containers/WebView/Webview";
import DailyCurrentGMaps from "../containers/DailyCurrentGMaps";

import Reports from "../containers/Reports";
import DutyStatusReport from "../containers/Reports/DutyStatusReport";
import HosRecapReport from "../containers/Reports/HosRecapReport";
import DriverDetailsReport from "../containers/Reports/DriversDetail";
import VehicleDetailsReport from "../containers/Reports/VehicleDetail";
import IftaStateMileage from "../containers/Reports/IftaStateMileage";
import DutyLogsReport from "../containers/Reports/DutyLogsReport";
import GpsDetail from "../containers/Reports/GpsDetail";

const styles = {
  mainContent: {
    flex: 1,
    padding: "0px",
    maxWidth: "100%",
    overflow: "scroll",
  },
};

function MainRoutes(props) {
  const { classes, accountBaseUrl } = props;

  return (
    <main className={classes.mainContent}>
      <Route exact path="/" component={LogIn} />

      <Route exact path={`/dashboard/admin`} component={AdminDashboard} />
      <Route exact path={`/dashboard/overview`} component={DashBoard} />
      <Route exact path={`/dashboard/accounts`} component={Account} />
      <Route exact path={`/dashboard/payments`} component={Payment} />
      <Route exact path={`/dashboard/admin-audit`} component={AdminAudit} />
      <Route exact path={`/dashboard/admin-users`} component={AdminUser} />
      <Route exact path={`/dashboard/fmcsa`} component={FmcsaAdmin} />
      <Route
        exact
        path={`/dashboard/accounts/:accountId`}
        component={AdminDetail}
      />
      <Route
        exact
        path={`/dashboard/admin-settings`}
        component={AdminSettings}
      />
      <Route
        exact
        path={`/dashboard/admin-reseller`}
        component={AdminReseller}
      />
      <Route
        exact
        path={`/dashboard/admin-reseller/:resellerId`}
        component={AdminResellerDetail}
      />
      <Route exact path={`/dashboard/support`} component={AdminSupport} />
      <Route exact path={`/dashboard/support/queue`} component={QueueDetail} />
      <Route exact path={`/dashboard/shipping`} component={AdminShipping} />
      <Route exact path={`/dashboard/leads`} component={AdminLeads} />
      <Route exact path={`/dashboard/admin-todo`} component={AdminTodo} />
      <Route exact path={`/dashboard/whatsapp`} component={AdminWhatsapp} />

      <Route
        exact
        path={`${accountBaseUrl}/webview/:driverId`}
        component={Webview}
      />

      <Route
        exact
        path={`${accountBaseUrl}/status/view`}
        component={DailyCurrentGMaps}
      />

      <Route
        exact
        path={`${accountBaseUrl}/status/diary/:driverId`}
        component={DailyDiary}
      />

      <Route
        exact
        path={`${accountBaseUrl}/status/detail/:driverId`}
        component={DailyStatusDetail}
      />
      <Route
        exact
        path={`${accountBaseUrl}/status/multi/:driverId`}
        component={DailyStatusMulti}
      />
      <Route
        exact
        path={`${accountBaseUrl}/status/detail/:driverId/verify`}
        component={VerifyLogs}
      />
      <Route
        exact
        path={`${accountBaseUrl}/status/detail/:driverId/review`}
        component={ReviewLogs}
      />

      <Route exact path={`${accountBaseUrl}/tracking`} component={Tracking} />
      <Route exact path={`${accountBaseUrl}/dvir`} component={Dvir} />
      <Route
        exact
        path={`${accountBaseUrl}/dvir/:dvirId`}
        component={DvirDetail}
      />
      <Route exact path={`${accountBaseUrl}/document`} component={Document} />
      <Route exact path={`${accountBaseUrl}/fmcsa/create`} component={Fmcsa} />
      <Route
        exact
        path={`${accountBaseUrl}/fmcsa/history`}
        component={FmcsaHistory}
      />
      <Route exact path={`${accountBaseUrl}/log-edits`} component={LogEdits} />

      <Route
        exact
        path={`${accountBaseUrl}/unidentified`}
        component={UnidentifiedDriving}
      />
      <Route
        exact
        path={`${accountBaseUrl}/malfunctions`}
        component={Malfunctions}
      />
      <Route
        exact
        path={`${accountBaseUrl}/violations`}
        component={Violations}
      />
      <Route
        exact
        path={`${accountBaseUrl}/notifications`}
        component={Notifications}
      />
      <Route
        exact
        path={`${accountBaseUrl}/live-share`}
        component={LiveShare}
      />
      <Route exact path={`${accountBaseUrl}/ifta`} component={IftaMileage} />
      <Route
        exact
        path={`${accountBaseUrl}/state-mileage`}
        component={IftaMileageBeta}
      />
      <Route
        exact
        path={`${accountBaseUrl}/state-mileage/detail`}
        component={IftaMileageDetail}
      />

      <Route path={`${accountBaseUrl}/report`} component={Report} />
      <Route
        exact
        path={`${accountBaseUrl}/report/state-mileage`}
        component={IftaMileageBeta}
      />
      <Route
        exact
        path={`${accountBaseUrl}/report/fmcsa/create`}
        component={Fmcsa}
      />
      <Route
        exact
        path={`${accountBaseUrl}/report/document`}
        component={Document}
      />
      <Route
        exact
        path={`${accountBaseUrl}/report/live-share`}
        component={LiveShare}
      />
      <Route path={`${accountBaseUrl}/settings`} component={Setting} />
      <Route
        exact
        path={`${accountBaseUrl}/settings/app`}
        component={SettingApp}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/user`}
        component={SettingUser}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/user/:driverId`}
        component={DriverDetails}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/carrier`}
        component={SettingCarrier}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/terminal`}
        component={SettingTerminal}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/equipment`}
        component={SettingEquipment}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/maintenance`}
        component={SettingMaintenance}
      />

      <Route
        exact
        path={`${accountBaseUrl}/settings/profile`}
        component={SettingProfile}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/account`}
        component={SettingAccount}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/exceptions`}
        component={SettingExceptions}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/editreason`}
        component={SettingEditReason}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/api`}
        component={SettingApi}
      />
      <Route
        exact
        path={`${accountBaseUrl}/settings/notification`}
        component={NotificationSetup}
      />
      <Route exact path={`${accountBaseUrl}/reports`} component={Reports} />
      <Route
        exact
        path={`${accountBaseUrl}/reports/duty-status`}
        component={DutyStatusReport}
      />
      <Route
        exact
        path={`${accountBaseUrl}/reports/hos-recap`}
        component={HosRecapReport}
      />
      <Route
        exact
        path={`${accountBaseUrl}/reports/drivers-detail`}
        component={DriverDetailsReport}
      />
      <Route
        exact
        path={`${accountBaseUrl}/reports/vehicle-detail`}
        component={VehicleDetailsReport}
      />
      <Route
        exact
        path={`${accountBaseUrl}/reports/state-mileage`}
        component={IftaStateMileage}
      />
      <Route
        exact
        path={`${accountBaseUrl}/reports/duty-logs`}
        component={DutyLogsReport}
      />
      <Route
        exact
        path={`${accountBaseUrl}/reports/gps-detail`}
        component={GpsDetail}
      />
    </main>
  );
}

MainRoutes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainRoutes);
